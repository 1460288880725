<!-- @format -->

<script>
export default {
    name: 'EventHeader',
    render() {
        return null;
    },
};
</script>
