<!-- @format -->

<template>
    <div>
        <div class="gst-event-banner pl-4 pr-2">
            <div class="u-height-100 d-flex flex-row">
                <div class="d-flex align-center flex-shrink-1 justify-center">
                    <BaseImage
                        ref="image"
                        :src="imageSrc"
                        :height="$vuetify.breakpoint.mdAndUp ? 60 : 42"
                        :width="$vuetify.breakpoint.mdAndUp ? 60 : 42"
                        :gradient="gradient"
                        class="gst-event-banner__image"
                        @error="errorLoadingImage = true"
                        @load="onImageLoadDo">
                        <template
                            v-if="errorLoadingImage"
                            v-slot:content>
                            <ImagePublicAssets
                                :path-key="imageSrc"
                                class="u-height-100"
                                :gradient="gradient" />
                        </template> </BaseImage
                    >/>
                </div>
                <div class="ml-2 d-flex flex-column flex-grow-1 mt-auto mb-auto">
                    <div class="d-flex flex-row align-center mb-1">
                        <VClamp
                            autoresize
                            :max-lines="1"
                            class="hyphens clamp gst-event-banner__name">
                            {{ item.name }}
                        </VClamp>
                        <v-btn
                            text
                            icon
                            x-small
                            class="gst-event-banner__info-button ml-2"
                            @click="openEventInfoModal">
                            <v-icon v-text="'$vuetify.icons.infoRound'" />
                        </v-btn>
                    </div>
                    <div class="d-flex flex-row align-center">
                        <VenueInfo
                            :max-lines="1"
                            class="hyphens clamp gst-event-banner__info"
                            :item="venueInfoLocation">
                            {{ eventDate }} •
                        </VenueInfo>
                        <FavoriteEntityButton
                            v-if="$vuetify.breakpoint.mdAndUp"
                            :entity-type="entityType"
                            :entity-id="eventId"
                            class="gst-event-banner__favorite-button ml-2" />
                    </div>
                </div>
                <div
                    v-if="!$vuetify.breakpoint.mdAndUp"
                    class="d-flex align-center flex-shrink-1 justify-center pl-2">
                    <FavoriteEntityButton
                        :entity-type="entityType"
                        :entity-id="eventId" />
                </div>
            </div>
        </div>
        <div class="d-flex align-end gst-event__sub-banner">
            <VClamp
                autoresize
                tag="div"
                :max-lines="!$vuetify.breakpoint.xs ? 2 : 1"
                class="hyphens clamp">
                {{ $t('subBanner.subtitle') }}
                <template #before>
                    <span
                        ><b>{{ $t('subBanner.title') }}</b></span
                    >
                </template>
                <template
                    v-if="$vuetify.breakpoint.xs"
                    #after="{ toggle, expanded }">
                    <button
                        class="pl-1"
                        @click="toggle">
                        {{
                            expanded
                                ? $t('subBanner.expandButton.showLess')
                                : $t('subBanner.expandButton.showMore')
                        }}
                    </button>
                </template>
            </VClamp>
        </div>
        <v-divider v-if="$vuetify.breakpoint.mdAndUp" />
    </div>
</template>

<script>
import EventBanner from '@core/views/event/TheEvent/EventBanner?CORE';

export default {
    name: 'EventBanner',
    extends: EventBanner,
    i18nOptions: {
        namespaces: 'main',
        keyPrefix: 'views.event.theEvent.eventBanner',
    },
};
</script>

<style lang="scss">
@import '@scssVariables';
@import '@scssMixins';

.gst-event__sub-banner {
    padding: theme-spacing(3, 4);
    background: theme-color('quinary');
    color: theme-color('quaternary');
    font-size: font-size('xs');

    button {
        color: theme-color('anchor');
        font-weight: font-weight('large');
    }
}

@include mobile-only {
    .gst-event__sub-banner {
        padding: theme-spacing(2, 4);
    }
}
</style>
