<!-- @format -->

<template>
    <DiscountBannerVue :discounts="discounts" />
</template>

<script>
import DiscountBannerVue from '@tenants/fanatics/components/DiscountBanner.vue';

export default {
    name: 'EventTicketBanner',
    components: {
        DiscountBannerVue,
    },
    props: {
        offers: {
            type: Array,
            required: true,
        },
    },
    computed: {
        discounts() {
            return this.offers[0].discounts;
        },
    },
};
</script>
