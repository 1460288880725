<!-- @format -->

<template>
    <div class="gst-event-ticket-detail-subtotal u-width-100">
        <div class="d-flex flex-wrap justify-space-between">
            <span class="text-capitalize gst-event-ticket-detail-subtotal__price-label">
                {{ $t('_common:terms.subTotal').toLowerCase() }}
            </span>
            <div class="d-flex gst-event-ticket-detail-subtotal__price">
                <span
                    v-if="showMarketPrice"
                    class="gst-event-ticket-detail-subtotal__market-price mr-2 u-text-strike-though">
                    {{ marketPrice | currencyFull(currency) }}
                </span>
                {{ price | currencyFull(currency) }}
            </div>
        </div>
        <div class="d-flex flex-wrap justify-space-between mt-1">
            <span class="text-lowercase gst-event-ticket-detail-subtotal__selected-items">
                {{ selectedItemsFormatted }}
            </span>
            <span
                v-if="isAllInclusivePricing"
                class="gst-event-ticket-detail-subtotal__taxes-and-fees-label ml-auto">
                {{ $t('_common:terms.includingTaxesAndFees') }}
            </span>
        </div>
    </div>
</template>

<script>
import SessionStorageService from '@core/services/SessionStorageService';
import EventTicketDetailSubtotal from '@core/views/event/TheEvent/components/subtotals/EventTicketDetailSubtotal.vue?CORE';

export default {
    name: 'EventTicketDetailSubtotal',
    extends: EventTicketDetailSubtotal,
    props: {
        price: {
            type: Number,
            required: true,
        },
        marketPrice: {
            type: Number,
            default: null,
        },
        currency: {
            type: String,
            required: true,
        },
        selectedItems: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        isAllInclusivePricing() {
            return SessionStorageService.eventIsAllInclusivePricing.get();
        },
    },
};
</script>
